if ("requestIdleCallback" in window) {
    requestIdleCallback(initButtonsAndEvents)
} else {
    setTimeout(initButtonsAndEvents, 1500)
}

async function initButtonsAndEvents() {
    const {buttonAddHelpVideo, buttonAddContentFn, buttonAddPackageFn} = await import("@/libs/buttonEvents.ts")

    buttonAddHelpVideo()
    buttonAddContentFn()
    buttonAddPackageFn()
    window.buttonAddContentFn = buttonAddContentFn
    window.buttonAddPackageFn = buttonAddPackageFn

    initCardWithVideo()

    if ("IntersectionObserver" in window) {
        initIntersectionObserver()
    }
}

function initCardWithVideo() {
    const cardWithVideo = document.querySelectorAll(".card-with-video")

    cardWithVideo.forEach((card) => {
        card.addEventListener(
            "click",
            () => {
                const videoUrl = card.dataset?.videoUrl
                if (videoUrl) {
                    window.eventBus.$emit("exampleVideo", videoUrl)
                }
            },
            {passive: true}
        )
    })
}

function initIntersectionObserver() {
    const cards = document.querySelectorAll(".card")
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0.5) {
                    entry.target.classList.add("in-view")
                } else if (entry.intersectionRatio < 0.2) {
                    entry.target.classList.remove("in-view")
                }
            })
        },
        {threshold: [0.2, 0.5]}
    )

    cards.forEach((card) => observer.observe(card))
}
